import React from "react"

export default class Interests extends React.Component {
  state = {
    interests: [
      "Running",
      "Web Development",
      "Biking",
      "Minneapolis / St. Paul",
      "Technology",
      "Reading",
      "Home Automation",
      "Customer Success",
    ],
  }

  render = () => {
    return (
      <>
        {/* <h1>Interests</h1> */}
        <div className="interests">
          <ul className="interests-list">
            {this.state.interests.map(interest => {
              return <li>{interest}</li>
            })}
          </ul>
        </div>
      </>
    )
  }
}
