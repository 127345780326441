import React from "react"
import buses from "../images/buses.png"
import llt from "../images/llt.png"
import bookshelf from "../images/bookshelf-november.png"
import papyr from "../images/papyr.png"

export default class Papyr extends React.Component {
  state = {
    skills: [
      "AWS Elastic Beanstalk",
      "AWS Polly",
      "AWS EC2",
      "AWS S3",
      "AWS SES",
      "React",
      "Node.js",
      "Express",
      "MySQL",
      "Passport",
      "SemanticUI",
      "Heroku",
      "MongoDB",
      "React Bootstrap",
      "Chart.js",
      "Quill",
      "Netlify",
      "socket.io/WebSockets",
    ],
    projects: [
      {
        title: `Papyr.io`,
        description: [
          `Originally a personal project to keep track of books we have in our house and make it easy to enter them via a scanned ISBN. (Very) slowly morphed into allowing multiple users, being able to keep track of books you've lent, letting you know what your friends are reading, and keeping track of book clubs, personal book notes. Mostly a stale project in 2023, but will always be a work in progress. (and could be picked back up any day!)`,
        ],
        live: true,
        url: "https://papyr.io",
        github: "https://github.com/mjgall/bookshelf",
        skills: [
          "React",
          "Node.js",
          "Express",
          "MySQL",
          "Passport",
          "TailwindCSS",
        ],
        image: papyr,
        urlClean: "papyr.io",
      },
      // {
      //   title: "Language Learning Tool",
      //   description: [
      //     `Inspired by foreign-language immersion learning, this app helps students associate spoken language with images instead of memorization of translated vocabulary. Users create courses by drawing images and entering corresponding text, which is converted to spoken audio by Amazon Polly.`,
      //     `Users can enroll in their own or other user-created courses, where they take quiz-format lessons to match the spoken phrase with one of four images.`,
      //     `Features authentication using Google or a traditional email and password combination that can be reset if needed.`,
      //   ],
      //   live: false,
      //   url: "https://llt.gllghr.io",
      //   github: "https://github.com/mjgall/mike-bull-soft",
      //   skills: [
      //     "AWS Polly",
      //     "AWS EC2",
      //     "AWS Elastic Beanstalk",
      //     "AWS S3",
      //     "AWS SES",
      //     "React",
      //     "Node.js",
      //     "Express",
      //     "MySQL",
      //     "Passport",
      //     "SemanticUI",
      //   ],
      //   image: llt,
      //   urlClean: "llt.gllghr.io",
      // },
      // {
      //   title: "Metro Transit Bus Tracker",
      //   description: [
      //     `Displays Metro Transit bus arrival times for your frequent stops and your current Metro Transit card balance. Utilizes the Google OAuth flow, the Metro Transit API, and page scraping to fetch additional information not available via the API.`,
      //     `Currently not updated for Metro Transit's recent changes to their API.`,
      //   ],
      //   live: false,
      //   url: "https://buses.mpls.app",
      //   github: "https://github.com/mjgall/buses.mpls.app",
      //   skills: [
      //     "Heroku",
      //     "React",
      //     "Node.js",
      //     "Express",
      //     "MongoDB",
      //     "Passport",
      //     "SemanticUI",
      //   ],
      //   image: buses,
      //   urlClean: "buses.mpls.app",
      // },
    ],
  }

  render = () => {
    return (
      <>
        <h1 id="projects">papyr.io</h1>
        <div className="projects">
          {this.state.projects.map(project => {
            return (
              <div className="project">
                <div>
                  {project.description.map(paragraph => (
                    <p>{paragraph}</p>
                  ))}
                </div>
                <div className="project-details-container">
                  <div
                    style={{
                      display: "grid",
                      gridTemplateRows: "50px 50px",
                      gridGap: "1rem",
                      padding: "1rem 0",
                    }}
                  >
                    <img
                      className="project-screenshot"
                      alt={`Screenshot of ${project.title}`}
                      src={project.image}
                      style={{ width: "100%" }}
                    ></img>
                  </div>
                  <ul
                    className="project-skills"
                    style={{ listStyleType: "none" }}
                  >
                    {project.skills.map(skill => (
                      <li>{skill}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )
          })}
        </div>
      </>
    )
  }
}
