import { Link } from "gatsby"
import React from "react"

export default class Introduction extends React.Component {
  render = () => {
    return (
      // <p>
      //   Detail focused and experienced SaaS professional interested in business, technology, and positive product experiences that ensure customer success and result in predictable and retained recurring revenue. Demonstrated skills in technology, web development, design, and software. Proven experience in subscription SaaS, customer service, and customer experience, including churn prevention, product support, product demonstration, and development based on customer feedback.
      // </p>
      // <p>
      //   A detail-oriented, experienced customer advocate, I leverage my product expertise and technical experience to
      //   find or create solutions to any customer issue, helping to ensure customers get the most out of the products I
      //   support.
      // </p>
      <p>
        Leveraging product expertise and technical experience.{" "}
        <b>Head of Customer Success</b> at{" "}
        <b>
          <Link target="blank" to="https://prosperstack.com">
            ProsperStack
          </Link>
        </b>{" "}
        in Minneapolis, MN. Previously, Senior Enterprise Customer Success
        Manager at{" "}
        <Link target="blank" to="https://jamf.com">
          Jamf Software
        </Link>{" "}
      </p>
    )
  }
}
