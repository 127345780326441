import React from "react"

export default class WorkHistory extends React.Component {
  state = {
    work: [
      {
        companyName: "Jamf",
        title: "Enterprise Customer Success Manager II",
        startYear: "2021",
        endYear: "Present",
        description: [
          "Work with Jamf admins on any projects or new workflows they have planned in their environment to ensure they are successful from the beginning.",
          "Ensure Jamf Premium Support customers have the tools and resources needed to ensure successful deployment of their Apple devices. This involves workflow planning, project management and regularly scheduled status calls.",
          "Manage the development and cultivation of a relationship with Jamf customers to encourage frequent and effective communication.",
          "Main point of contact for all workflow and business related inquiries and requests to a strategically assigned list of customers.",
        ],
      },
      // {
      //   companyName: 'Jamf',
      //   title: 'Enterprise Customer Success Manager',
      //   startYear: '2020',
      //   endYear: '2021',
      //   description: [

      //   ],
      // },
      // {
      //   companyName: 'CATS Software',
      //   title: 'Customer Success Manager',
      //   startYear: '2018',
      //   endYear: '2020',
      //   description: [
      //     'Designed and implemented first Customer Success program at company, based on industry standards and existing knowledge of internal customer lifecycle and the CATS product.',
      //     'Introduced Segment software to funnel data to Customer Success software. From this usage data, created a model of a successful CATS user and account using custom health scores and further segmentation. ',
      //     'Worked with customers to ensure onboarding, user adoption, retention and overall customer health.',
      //     'Ascertained customer goals and translated into implementation plans to provide initial value. ',
      //     'Led retention and growth efforts among most valuable customers by understanding their business needs and helping them succeed. ',
      //     'Monitored accounts to identify “at-risk” clients and contacted proactively to eliminate possible churn. ',
      //     'Researched successful clients and analyzed their usage to find patterns of success. ',
      //     'Coordinated and created education opportunities for customers, such as webinars, training resources, and Knowledge Base articles to ensure user confidence with the product. ',
      //     'Handled all contract renewals for annually billed customers and negotiations with any customers involving ongoing costs.',
      //     'Orchestrated and executed large-scale customer communication involving pricing changes, policy changes, and any notifications involving large portions of the user base. ',
      //   ],
      // },
      // {
      //   companyName: 'CATS Software',
      //   title: 'Inside Sales',
      //   startYear: '2016',
      //   endYear: '2018',
      //   description: [
      //     'Managed entire sales process from first interaction to product demonstration and onboarding.',
      //     'Collected and introduced client feedback to Product team.',
      //     'Led various projects including pricing model research and plan restructuring, customer success models, and sales process analysis.',
      //     'Contributed to projects outside of sales scope pertaining to product design and experience.',
      //     'Ensured customer success within product and all interactions with company.',
      //     'Achieved and exceeded sales goals set forth by management by 180% in 2017 and 265% in 2018.',
      //   ],
      // },
      // {
      //   companyName: 'Sun Street Breads',
      //   title: 'Front of House Staff/Manager',
      //   startYear: '2014',
      //   endYear: '2016',
      //   description: [
      //     'Responsibilities included all customer interaction as well as assisting the kitchen and bakery throughout the day. ',
      //     'Managed end of day cash handling and ensured positive customer experiences.',
      //   ],
      // },
      // {
      //   companyName: "City of Minneapolis, City Council",
      //   title: "Temporary Council Associate",
      //   startYear: "2015",
      //   endYear: "2015",
      //   description: [
      //     "Created public literature, drafted remarks for the Council Member, maintained communications with constituents, and researched relevant policy issues within the City of Minneapolis and Ward 11, pertaining to transit, crime, labor, and housing efforts.",
      //   ],
      // },
    ],
  }

  render = () => {
    return (
      <>
        {/* <h1 id="work">Experience</h1> */}
        <div className="work-history">
          {this.state.work.map(entry => {
            return (
              <div className="work-history-entry">
                <h2 className="work-history-title">{entry.title}</h2>
                <h4 className="work-history-company">{entry.companyName}</h4>
                <h4 className="work-history-dates">
                  {entry.startYear === entry.endYear
                    ? entry.startYear
                    : `${entry.startYear} - ${entry.endYear}`}
                </h4>
                <p className="work-history-description">
                  <ul>
                    {entry.description.map(point => {
                      return <li>{point}</li>
                    })}
                  </ul>
                </p>
              </div>
            )
          })}
        </div>
      </>
    )
  }
}
