import React from 'react';

export default class Interests extends React.Component {
  state = {
    education: [
      {
        school: 'St. Olaf College',
        major: 'B.A. Political Science, German Language',
        graduation: '2014',
        // description:
        //   'St. Olaf Academic Scholarship, Schlichting German Scholarship, Great Conversation Program, Term in the Middle East, St. Olaf Rugby',
      },
    ],
  };

  render = () => {
    return (
      <>
        <h1>Education</h1>
        <div className="education">
          {this.state.education.map(entry => {
            return (
              <div className="education-entry">
                <h3 className="education-school">{entry.school}</h3>
                {/* <h4 className="education-major">{entry.major}</h4> */}
                <h4 className="education-graduation">{entry.graduation}</h4>
              </div>
            );
          })}
        </div>
      </>
    );
  };
}
