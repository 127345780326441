import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/Bio"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Introduction from "../components/Introduction"
import WorkHistory from "../components/WorkHistory"
import Interests from "../components/Interests"
import Education from "../components/Education"
import Papyr from "../components/Papyr"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="" />
      <Bio />
      <Introduction></Introduction>
      <Interests></Interests>
      {/* <WorkHistory></WorkHistory> */}

      {/* <Education></Education> */}
      <Papyr></Papyr>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            published
          }
        }
      }
    }
  }
`
